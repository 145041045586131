import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class AboutIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="About" />

        <section className="mb-5">
          <h4>About Me</h4>
          <div className="text-justify">
            <p>I’m a full-stack software engineer and coding bootcamp instructor, currently living and working in San Francisco.</p>
            <p>As an instructor of code, I spend a lot of time considering how best to pass on knowledge to others, as well as learning (and relearning) a wide range of technical topics myself.</p>
            <p>I started this website mainly to document my own learnings, but also hope that the content provided is of some use to others navigating their way through the world of software engineering.</p>
          </div>
        </section>
      </Layout>
    )
  }
}

export default AboutIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
